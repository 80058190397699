import React from "react"
import { withTranslation } from 'react-i18next';
import Heading from "./Heading"

function AboutUs({ t }) {
  return (
    <div className="container-fluid">
      <Heading
        title={t('header.aboutUs')}
        id="about-us"
      />
      <div className="row">
        <div className="col-lg-12">
          {
            t('aboutUs.sections', {returnObjects: true}).map((section) =>
              <div><div>{section}</div><br/></div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(AboutUs)
