import React from "react"
import scone1 from '../assets/SCN_01.jpeg'
import scone2 from '../assets/SCN_02.jpeg'
import scone3 from '../assets/SCN_03.jpeg'
import saleSheet from '../assets/Sell Sheet - Scones.pdf'
import shortbread1 from '../assets/SBD_01.jpeg'
import shortbread2 from '../assets/SBD_02.jpeg'
import comingSoon from '../assets/Coming_Soon.png'

import { withTranslation } from 'react-i18next';
import Heading from "./Heading"
import ProductBox from "./ProductBox"

function Product({ t }) {
  return (
    <div className="container-fluid pb-3">
      <Heading
        title={t('header.products')}
        id="product"
      />
      <div className="text-center mb-3">{t('product.message')}</div>
      <div className="row">
        <ProductBox
          title={t('product.scones.title')}
          id="carousel-scones"
          modalId="modal-carousel-scones"
          modalContent={t('product.scones.modalContent', {returnObjects: true})}
          descriptions={t('product.scones.descriptions', {returnObjects: true})}
          items={[scone1, scone2, scone3]}
          downloadItems={[{content: t('product.scones.sellSheet'), item: saleSheet}]}
        />
        <ProductBox
          title={t('product.shortbreads.title')}
          id="carousel-shortbread"
          modalId="modal-carousel-shortbread"
          modalContent={t('product.shortbreads.modalContent', {returnObjects: true})}
          descriptions={t('product.shortbreads.descriptions', {returnObjects: true})}
          items={[shortbread1, shortbread2]}
          downloadItems={[]}
        />
        <ProductBox
          title={t('product.other.title')}
          id="carousel-other"
          modalId="modal-carousel-other"
          modalContent={t('product.other.modalContent', {returnObjects: true})}
          descriptions={t('product.other.descriptions', {returnObjects: true})}
          items={[comingSoon]}
          downloadItems={[]}
        />
      </div>
    </div>
  )
}

export default withTranslation()(Product)
