import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import i18n from "i18next";
import { withTranslation } from 'react-i18next';

class Navbar extends Component {
  changeLanguage(language) {
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
    });
  }
  render() {
    const { t } = this.props;
    return ( 
      <nav className="navbar sticky-top navbar-light navbar-expand-lg navbar-background-color">
        <Link to="/#entry" className="navbar-brand mr-auto">{t('header.storeName').toUpperCase()}</Link>
        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="collpase navbar-collapse">
          <ul className="navbar-nav ml-auto">
            <li className="navbar-item">
            <Link to="/#about-us" className="nav-link">{t('header.aboutUs')}</Link>
            </li>
            <li className="navbar-item">
            <Link to="/#product" className="nav-link">{t('header.products')}</Link>
            </li>
            <li className="navbar-item">
            <Link to="/#contact-us" className="nav-link">{t('header.contactUs')}</Link>
            </li>
            <li className="navbar-item">
            <Link to="/" className="nav-link" onClick={() => this.changeLanguage(t('switchLanguage'))}>{t('switchLanguage').toUpperCase()}</Link>
            </li>
          </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Navbar)