import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';

class Footer extends Component {

  render() {
    const { t } = this.props;
    return (
      <footer className="font-small pt-4 footer pb-2">
        <div className="container-fluid text-center text-md-left">
          <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
              <h4 id="contact-us" className="footer-header text-uppercase font-weight-bold">{t('footer.contactUs.title')}</h4>
              <div className="mt-3">
                <strong>Tel.</strong> (514) 487-3933 <br/>
                <strong>Email:</strong> info@gryphonbakery.com
              </div>
              <div className="mt-3"><a href="http://gryphonbakery.com" target="_blank">{t('footer.contactUs.retailWebsite')}</a></div>
            </div>
            <div className="col-md-6 mt-md-0">
              <h4 className="footer-header text-uppercase font-weight-bold">{t('footer.distributors.title')}</h4>
              <div className="float-left"><a href="https://ventesrudolph.com/" target="_blank">Rudolph</a></div><br/>
              <div className="float-left"><a href="https://www.gfs.ca/" target="_blank">Gordon Food Service</a></div><br/>
              <div className="float-left"><a href="https://www.dubord.ca/" target="_blank">Dubord & Rainville</a></div><br/>
              <div className="float-left"><a href="https://hectorlarivee.com/" target="_blank">Hector Larivée </a></div><br/>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default withTranslation()(Footer)